<div class="reports-page">
    <div class="container">
        <h2 class="title">Հաշվետվություններ</h2>
        <p>Այստեղ ներկայացված են «ՖԼԱՅ-ՆԵթ» ընկերության ֆինանսական հաշվետվությունները:</p>
        <div class="reports-list">
            <div class="list-item">
                <a href="assets/files/2021-1.pdf" target="_blank">
                    <div class="title">
                        «ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ
                        (2021թ., I եռամսյակ)
                    </div>
                    <i class="fa fa-file-pdf"></i>
                </a>
            </div>
            <div class="list-item">
                <a href="assets/files/2021-1.pdf" target="_blank">
                    <div class="title">
                        «ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ
                        (2021թ., II եռամսյակ)
                    </div>
                    <i class="fa fa-file-pdf"></i>
                </a>
            </div>
            <div class="list-item">
                <a href="assets/files/2021-1.pdf" target="_blank">
                    <div class="title">
                        «ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ
                        (2021թ., III եռամսյակ)
                    </div>
                    <i class="fa fa-file-pdf"></i>
                </a>
            </div>
            <div class="list-item">
                <a href="assets/files/2021-1.pdf" target="_blank">
                    <div class="title">
                        «ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ
                        (2021թ., VI եռամսյակ)
                    </div>
                    <i class="fa fa-file-pdf"></i>
                </a>
            </div>
            <div class="list-item">
                <a href="assets/files/2022-1.pdf" target="_blank">
                    <div class="title">
                        «ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ
                        (2022թ., I եռամսյակ)
                    </div>
                    <i class="fa fa-file-pdf"></i>
                </a>
            </div>
            <div class="list-item">
                <a href="assets/files/2022-2.pdf" target="_blank">
                    <div class="title">
                        «ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ
                        (2022թ., II եռամսյակ)
                    </div>
                    <i class="fa fa-file-pdf"></i>
                </a>
            </div>
            <div class="list-item">
                <a href="assets/files/2022-3.pdf" target="_blank">
                    <div class="title">
                        «ՖԼԱՅ ՆԵԹ» ընկերության տեղեկատվություն հիմնական տեխնիկատնտեսական ցուցանիշների վերաբերյալ
                        (2022թ., III եռամսյակ)
                    </div>
                    <i class="fa fa-file-pdf"></i>
                </a>
            </div>
        </div>
    </div>
</div>